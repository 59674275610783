import { MSForm, MSInputField, MSFormField } from './Forms';

export class MSCheckField extends MSInputField {
    html() {
        return '<div class="form-check">' + this.labeledHtml(`<input class="form-check-input" type="${this.m_cfig.type}" id="ms_form_uid-${this.m_uid}" key="${this.m_cfig.key}" name="${this.m_cfig.key}">`) + this.help_html() + "</div>";
    }

    setValue(iValue) {
        this.$m_input.prop('checked', iValue ? true : false);
        // $('#ms_form_uid-' + this.m_uid)[0].checked = (iValue ? true : false);
    }

    getValue() {
        return this.$m_input.is(':checked');
        // return $('#ms_form_uid-' + this.m_uid).is(':checked');
    }
}

export class MSSwitchField extends MSCheckField {
    html() {
        return '<div class="form-check form-switch">' + this.labeledHtml(`<input class="form-check-input" type="checkbox" id="ms_form_uid-${this.m_uid}" key="${this.m_cfig.key}" name="${this.m_cfig.key}">`) + this.help_html() + "</div>";
    }
}

export class MSRadiosField extends MSFormField {

    $create() {
        let html = '';
        let i = 0;
        let form_check_class = this.m_cfig.class && this.m_cfig.class.form ? this.m_cfig.class.form : "";
        for (const k in this.m_cfig.options) {
            i++;
            html += `
            <div class="form-check ${form_check_class}">
            <input class="form-check-input" type="radio" name="${this.m_uid}" id="ms_form_uid-${this.m_uid}-${i}" xvalue="${k}">
            <label class="form-check-label" for="ms_form_uid-${this.m_uid}-${i}">` + htmlent(this.m_cfig.options[k]) + `</label>
            </div>`;
        }

        const $res = $(html);
        //$res.find('input').changed((e) => this.m_form.on_changed(e));

        return $res;
    }

    setValue(iValue) {
        const $obj = this.$m_element.find(`input[xvalue="${iValue}"]`);
        if ($obj.length) {
            $obj[0].checked = true;
        }
    }

    getValue() {
        return this.$m_element.find('input:checked').attr('xvalue');
    }
}

export class MSHtmlField extends MSFormField {
    html() {
        return this.m_cfig.html;
    };
}

export class MSSeparatorField extends MSFormField {
    html() {
        if (!this.m_cfig.label) {
            return '<hr>';
        }
        return '<div class="text-divider">' + htmlent(this.m_cfig.label) + '</div>'
    };
}

export class MSColorField extends MSFormField {
    html() {
        let $res = $("<div/>").addClass("color_field d-flex");
        
        this.$m_input = $("<input/>").attr({ "type": "color", "id": "ms_form_uid-" + this.m_uid })
            .addClass(this.m_cfig.class)
            .prop('title', this.m_cfig.help ?? '')
            .prop('key', this.m_cfig.key ?? '')
            .appendTo($res);
        if (this.m_cfig.label) {
            $("<label/>").addClass("label ms-2 my-auto").text(this.m_cfig.label).appendTo($res)
        }
        if (this.m_cfig.default) {
            this.$m_trash = $('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">\\<path d="M177.7 32l92.5 0c5.5 0 10.6 2.8 13.6 7.5L299.1 64 148.9 64l15.3-24.5c2.9-4.7 8.1-7.5 13.6-7.5zM336.9 64L311 22.6C302.2 8.5 286.8 0 270.3 0L177.7 0C161.2 0 145.8 8.5 137 22.6L111.1 64 64.1 64 32 64 16 64C7.2 64 0 71.2 0 80s7.2 16 16 16l18.3 0L59.8 452.6C62.1 486.1 90 512 123.6 512l200.8 0c33.6 0 61.4-25.9 63.8-59.4L413.7 96 432 96c8.8 0 16-7.2 16-16s-7.2-16-16-16l-16 0-32.1 0-47.1 0zm44.8 32L356.3 450.3C355.1 467 341.2 480 324.4 480l-200.8 0c-16.8 0-30.7-13-31.9-29.7L66.4 96l315.3 0zM219.9 189.3c-5.9-6.6-16-7.1-22.6-1.2l-80 72c-3.4 3-5.3 7.4-5.3 11.9s1.9 8.9 5.3 11.9l80 72c6.6 5.9 16.7 5.4 22.6-1.2s5.4-16.7-1.2-22.6l-49-44.1 70.3 0c26.5 0 48 21.5 48 48l0 40c0 8.8 7.2 16 16 16s16-7.2 16-16l0-40c0-44.2-35.8-80-80-80l-70.3 0 49-44.1c6.6-5.9 7.1-16 1.2-22.6z"/></svg>');
            this.$m_trash.appendTo($res).on('click', () => {
                this.m_is_default = true;
                this.$m_input.val(this.m_cfig.default);
                this.update();
                this.m_form.updateButtons();
                this.m_form.trigger("change",this.$m_input)
            });
            this.$m_input.on('change', () => {
                this.m_is_default = false;
                this.update();
                this.m_form.updateButtons();
            });
            this.m_is_default = true;
            this.update();
        }
        return $res;
    };

    update() {
        if (this.$m_trash) {
            if (this.m_is_default) {
                this.$m_trash.hide();
                this.$m_input.addClass("unset");
                this.$m_input.removeClass("set");
                this.$m_input.val(this.m_cfig.default);
            } else {
                this.$m_trash.show();
                this.$m_input.addClass("set");
                this.$m_input.removeClass("unset");
            }
        }
    }

    setValue(iValue) {
        this.$m_input.val(iValue ?? (this.m_cfig.default ?? "#000000"));
        if (this.$m_trash) {
            if (iValue) {
                this.m_is_default = false;
            } else {
                this.m_is_default = true;
            }
            this.update();
        }
    }

    getValue() {
        if (this.m_is_default) {
            return undefined;
        }
        return this.$m_input.val();
    }
}

export function load() {
    MSForm.registerFieldClass('checkbox', MSCheckField);
    MSForm.registerFieldClass('radios', MSRadiosField);
    MSForm.registerFieldClass('switch', MSSwitchField);
    MSForm.registerFieldClass('html', MSHtmlField);
    MSForm.registerFieldClass('separator', MSSeparatorField);
    MSForm.registerFieldClass('color', MSColorField);
}