export class JsonRpcErrorCode {
    static parseErr = -32700;
    static invalidRequest = -32600;
    static methodNotFound = -32601;
    static badParams = -32602;
    static internalErr = -32603;

    // MS Errors
    static unknown = -32000;
    static notFound = -32001;
    static unauthorized = -32002;
    static forbidden = -32003;
    static invalidCredential = -32004;
    static timeMismatch = -32005;
    static timeout = -32006;
    static notReady = -32007;
    static notReachable = -32008;
    static alreadyExists = -32009;
};

export class JsonRpcError {
    payLoad;
    code;
    message;
    constructor(iErrorObj) {
        Object.assign(this, iErrorObj);
        this.code ??= JsonRpcErrorCode.internalErr;
        this.message ??= "Unknown error";
    }
}

export class JsonRpcClient {

    m_bearer;
    m_default_url;
    m_default_timeout;
    m_log_base;

    constructor(iUrl, iDefaultTimeout) {
        this.m_url = iUrl;
        this.m_default_timeout = iDefaultTimeout;
        this.m_log_base = {};
    }

    setBearer(iBearer) {
        this.m_bearer = iBearer;
    }

    async fetch(iMethod, iParams, iOptions = {}) {
        const payload = {
            jsonrpc: "2.0",
            method: iMethod,
            params: iParams,
        }

        const url = iOptions.url ? iOptions.url : this.m_default_url;  

        const timeout = iOptions.timeout ? iOptions.timeout : this.m_default_timeout;  
        const controller = new AbortController();
        const timeout_id = setTimeout(() => controller.abort('timeout'), timeout);

        const headers = iOptions.headers ? iOptions.headers : {};
        if (! headers['Content-Type']) {
            headers['Content-Type'] = 'application/json';
        }
        if (! headers['Accept']) {
            headers['Accept'] = 'application/json';
        }
        if (this.m_bearer) {
            headers['Authorization'] = `Bearer ${this.m_bearer}`;
        }

        const fetchParams = {
            method: "POST",
            mode: 'cors', // no-cors, *cors, same-origin
            cache: "no-cache",
            redirect: 'follow',
            credentials: 'omit', // include, *same-origin, omit
            referrerPolicy: this.m_referrer_policy ?? 'strict-origin-when-cross-origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            headers: headers,
            body: JSON.stringify(payload),
            signal: controller.signal,
        };

        try {
            const response = await fetch(url, fetchParams);
            clearTimeout(timeout_id);

            if (response.ok && response.status == 200 || response.status == 599) {
                const rpc_reply = await response.json();
                rpc_reply.http_code = response.status;
                if (rpc_reply.error) {
                    throw new JsonRpcError(rpc_reply.error);
                }
                return rpc_reply.result;
            }

            const body = await response.text();
            throw new JsonRpcError({
                code: JsonRpcErrorCode.notReachable,
                is_transport_error : true,
                http_code: response.status,
                http_body: body,
                message: 'HTTP ERROR:' + body
            });
        } catch (err) {
            clearTimeout(timeout_id);
            if (err instanceof JsonRpcError) {
                throw err;
            }
            if (err.name == 'timeout') {
                throw new JsonRpcError({
                    code: JsonRpcErrorCode.timeout,
                    is_transport_error : true,
                    message: "The web service timed out"
                });
            }
            throw new JsonRpcError({
                code: JsonRpcErrorCode.internalErr,
                is_transport_error : true,
                message: "Can't get response text",
                data: JSON.parse(JSON.stringify(err, Object.getOwnPropertyNames(err))),
            });
        }
    }

    log(iParams) {        


        const log = Object.assign({}, this.m_log_base, iParams);

        if (iParams.detail && typeof iParams.detail === 'object') {
            if (Object.keys(iParams.detail).length == 0) {  // it is an Error object
                log.detail = JSON.parse(JSON.stringify(iParams.detail, Object.getOwnPropertyNames(iParams.detail)));
            }
        }
        this.fetch('log', log, { url: '/log'}).catch((err) => {
            console.warn('Failed to send log', err);
        });
    }

    d_log(iMessage, iDetail) {this.log({sev : 'D', msg : iMessage, detail : iDetail});}
    i_log(iMessage, iDetail) {this.log({sev : 'I', msg : iMessage, detail : iDetail});}
    n_log(iMessage, iDetail) {this.log({sev : 'N', msg : iMessage, detail : iDetail});}
    w_log(iMessage, iDetail) {this.log({sev : 'W', msg : iMessage, detail : iDetail});}
    e_log(iMessage, iDetail) {this.log({sev : 'E', msg : iMessage, detail : iDetail});}
}
