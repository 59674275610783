export class MSModal {

    constructor(iConfig) {
        this.m_cfig = iConfig;
    }

    createBody() {
        if (this.m_cfig.html) {
            return $('<div/>').html(this.m_cfig.html);
        }
        return $('<div/>').text(this.m_cfig.message ?? '');
    }

    value() {
        return this.m_value;
    }

    onBeforeShow() {
    }

    create() {
        console.warn('create is obsolete, please use $create instead');
        return this.$create();
    }

    $create() {
        const $modal=$("<div/>").addClass("modal fade");
            var $modal_dialog=$("<div/>").addClass("modal-dialog").appendTo($modal);
                var $modal_content=$("<div/>").addClass("modal-content").appendTo($modal_dialog);
                    var $modal_toolbar=$("<div/>").addClass("modal-toolbar bold text-white w-100 mb-2 d-flex justify-content-between").appendTo($modal_content);
                    var $modal_header=$("<div/>").addClass("modal-header").appendTo($modal_content);
                        var $title=$("<div/>").addClass("modal-title").appendTo($modal_header);
                    var $modal_body=$("<div/>").addClass("modal-body").appendTo($modal_content);
                    var $modal_footer=$("<div/>").addClass("modal-footer").appendTo($modal_content);

        if(this.m_cfig.option){
            if(this.m_cfig.option.dialog){
                $modal_dialog.addClass(this.m_cfig.option.dialog.class)
            }
            if(this.m_cfig.option.title && this.m_cfig.option.title.class){
                $title.addClass(this.m_cfig.option.title.class)
            }
            if(this.m_cfig.option.remove_footer){
                $modal_footer.remove()
            }
        }

        if (this.m_cfig.title) {
            $modal.find('.modal-title').text(this.m_cfig.title);
        }else if(this.m_cfig.has_close_box) {
            $(`<button type="button" class="btn-close" aria-label="Close"></button>`).appendTo($modal.find('.modal-header'));
        } else{
            $modal_header.remove();
        }

        this.createBody().appendTo($modal.find('.modal-body'));        

        if (!this.m_cfig.buttons || this.m_cfig.buttons.length == 0) {
            this.m_cfig.buttons = [{
                title: 'OK'
            }];
        }

        if(this.m_cfig.option && this.m_cfig.option.type == "communication"){
            $modal_footer.remove();

            this.m_cfig.buttons.forEach((btn) => {
                var $div=$("<button/>").addClass("btn "+btn.class).text(btn.title).appendTo($modal_toolbar);
                if(btn.action) {
                    $div.addClass('has-action');
                    $div.on("click",(e) => {
                        btn.action();
                    });
                }
            });
        }else{  
            this.m_cfig.buttons.forEach((btn) => {
                const $btn = $('<button type="button" class="btn"></button>');
                $btn.text(btn.title);
                if (btn.class) {
                    $btn.addClass(btn.class);
                }
                if (btn.value !== undefined) {
                    $btn.attr('value', btn.value);
                }
                // Nouvelle condition : pour contôler si un bouton doit empêcher la fermeture de la modal lorsqu'il est cliqué / uniquement en cas d'actions dans la modal(ex : form...), s'applique sur les boutons avec le pram : preventClose :true;
                if (btn.preventClose) {
                    $btn.addClass('prevent-close');
                }
    
                if(btn.action) {
                    $btn.addClass('has-action');
                    $btn.on("click",(e) => {
                        btn.action();
                    });
                }
                $btn.appendTo($modal.find('.modal-footer'));
            });
        }

        return $modal;
    }

    async open() {
        this.$m_modal = this.$create();
        this.$m_modal.appendTo($('body'));
        this.bs_modal = new bootstrap.Modal(this.$m_modal.get(0), this.m_cfig);
        this.onBeforeShow();
        this.bs_modal.show();
        // this.$m_modal.find('button').off().on('click', (e) => {
        this.$m_modal.find('button').on('click', (e) => {
            if (!$(e.target).hasClass('prevent-close')) { // Ajoutez cette condition
                // console.log('hiding modal');
                this.m_value = $(e.target).attr('value');
                this.bs_modal.hide();
            }
        });
        return new Promise((resolve, reject) => {
            this.$m_modal.get(0).addEventListener('hide.bs.modal', () => {
                const value = this.value();
                // console.log('hide.bs.modal');
                //            bs_modal.dispose();
                this.$m_modal.remove();
                resolve(value);
            })
        });
    }

    close() {
        this.bs_modal.hide();
    }

    static async alert(iTile, iMessage) {
        return new MSModal({
            title: iTile,
            message: iMessage,
        }).open();
    }

    static async ask(iTitle, iMessage, iButtons) {
        const buttons = [];
        let value = 0;
        iButtons.forEach((btn) => {
            buttons.push({
                title: btn,
                value: value++,
            });
        });
        return new MSModal({
            title: iTitle,
            message: iMessage,
            buttons: buttons
        }).open();
    }

    static async form(iTile, iForm, iValue = {}) {
        return new MSModalForm({
            title: iTile,
            form: iForm,
            value: iValue
        }).open();
    }
}

export class MSModalForm extends MSModal {
    constructor(iConfig) {
        super(iConfig);
        this.m_cfig = iConfig;
        if (!this.m_cfig.buttons)
            this.m_cfig.buttons = [{
                title: _l('Annuler')
            }, {
                title: _l('OK'),
                value: 'submit'
            }
        ];
    }

    createBody() {
        this.m_form = new MSForm(this.m_cfig.form);
        const $res = this.m_form.$create();
        if (this.m_cfig.value) {
            setTimeout(() => {
                this.m_form.setValue(this.m_cfig.value);
            }, 0);
        }
        return $res;
    }

    value() {
        if (this.m_value == 'submit') {
            return this.m_form.getValue();
        }
    }

    enableSubmitBtn() {
        if (this.m_form.isValid()) {
            this.$m_submit_btn.removeClass('disabled');
        } else {
            this.$m_submit_btn.addClass('disabled');
        }
    }

    onBeforeShow() {
        this.$m_submit_btn = this.$m_modal.find('.modal-footer button[value="submit"]');
        this.m_form.setValidateBtn(this.$m_submit_btn);
        // this.m_form.on('change', () => {
        //     this.enableSubmitBtn();
        // });
        // this.enableSubmitBtn();
    }
}
