import {MSApp} from './MSApp';

export class MSAppPage {

    m_ref; //Une référence à la page.
    m_query; //Un objet contenant les paramètres de la query string de l'URL associée à la page.
    $m_div; // Une référence jQuery à l'élément <div> qui représente visuellement la page.

    //initialise la référence de la page avec la valeur passée en paramètre et initialise l'objet m_query avec un objet vide.
    constructor(iRef) {
        this.m_ref = iRef;
        this.m_query = {};
    }

    // Appelé avant $create à la première ouverture
    // Peut renvoyer une Promise... et du coup on attend la résolution avant de continuer.
    init() {
        return false
    }

    /**  used for backward compatibility
     *  Cette méthode est utilisée pour créer l'élément <div> représentant visuellement la page. Elle initialise également $m_div avec cet élément et appelle la méthode onCreate 
    */
    $create(iQuery) {
        this.$m_div = $("<div/>").addClass(this.m_type).attr({ "id": `${this.m_type}_${this.m_ref}`});
        this.onCreate(iQuery);
        return this.$m_div;
    }
    //Retourne true si la page a été créée (c'est-à-dire si $m_div n'est pas indéfini).
    isCreated() {
        return this.$m_div != undefined;
    }

    // Obsolete
    onCreate(iQuery) {
    }
    //Méthode appelée après la création de la page.
    onAfterCreate(iQuery) {
    }
    //Méthode appelée avant l'ouverture de la page
    onBeforeOpen(iQuery) {
    }
    //Méthode appelée lors de l'ouverture de la page
    onOpen(iQuery) {
    }
    //Méthode appelée après l'ouverture de la page.
    onAfterOpen(iQuery) {
    }
    //Méthode appelée lorsqu'il y a un changement dans la query string de l'URL associée à la page.
    onQueryChanged(iQuery) {
    }
    // Méthode pour mettre à jour les paramètres de la query string de l'URL associée à la page et appeler onQueryChanged.
    query(iQuery) {
        this.m_query = iQuery;
        this.onQueryChanged(iQuery);
    }
    // Méthode appelée lorsque la page est fermée.
    onClose() {
    }

    // contenu de la balise <title> ou false pour prendre le titre de l'app (MSApp.defaultPageTitle)
    title() {
        return MSApp.instance.defaultPageTitle();
    }

    seoActive() {
        return this.seoDescription() != undefined;
    }

    seoDescription() {
        return undefined;
    }

    // comma separated keywords
    seoKeywords() {
        return undefined;
    }

    applySeo() {
        // console.log('MSAppPage - applySeo')
        // console.log('MSAppPage - this.seoActive()',this.seoActive())
        // console.log('MSAppPage - this.seoDescription()',this.seoDescription())
        // console.log('MSAppPage - MSApp.instance.seoActive()',MSApp.instance.seoActive())
        // console.log('MSAppPage - MSApp.instance',MSApp.instance)

        // set title
        const title = this.title();
        $("title").text(title);
        $('meta[name="og:title"]').attr("content", title);
        $('meta[name="twitter:title"]').attr("content", title);

        $('meta[name="description"]').remove();
        $('meta[name="og:description"]').remove();
        $('meta[name="twitter:description"]').remove();
        $('meta[name="keywords"]').remove();
        $('meta[name="robots"]').remove();
        if (this.seoActive() && MSApp.instance.seoActive()) {
            $('<meta name="robots">').attr("content", "index, follow").appendTo("head");
            const description = this.seoDescription();
            // console.log(description)
            if (description) {
                $('<meta name="description">').attr("content", description).appendTo("head");
                $('<meta name="og:description">').attr("content", description).appendTo("head");
                $('<meta name="twitter:description">').attr("content", description).appendTo("head");
            }
            const keywords = this.seoKeywords();
            if (keywords) {
                $('<meta name="keywords">').attr("content", keywords).appendTo("head");
            }
        } else {
            // console.log('MSAppPage - applySeo', "noindex")
            $('<meta name="robots">').attr("content", "noindex, nofollow").appendTo("head");
        }
    }


    // updateWindowHash(iQuery) {
    //     const params = new URLSearchParams();
    //     for (const key in iQuery) {
    //         params.set(key, iQuery[key]);
    //     }
    //     window.location.hash = this.m_ref + '?' + params.toString();
    // }
}
