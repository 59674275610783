

import '../default.css';
import { MSAppPage } from './MSAppPage';
/**
 * Cette classe gère la navigation et le comportement des pages en fonction des hashs présents dans l'URL. Elle permet également d'enregistrer des fonctions spécifiques pour certains hashs et de gérer les événements de clic sur les liens.
 */
export class MSApp {
    //Un objet qui définit des indices z pour différents éléments de l'interface utilisateur, tels que l'en-tête, le menu, les notifications, etc.
    static z_index = {
        header: 1000,
        menu: 2000,
        toast: 3000,
        dialog: 4000,
        alert: 5000,
    };

    m_hash_pages; //Un objet servant à stocker les pages de l'application par leur référence.
    m_hash_functions; //Un objet servant à stocker les fonctions liées à des hashs spécifiques.
    m_current_page; //Une référence à la page actuellement ouverte
    m_current_page_ref; //La référence de la page actuellement ouverte.
    m_history_backing = 0; //Un compteur pour suivre l'historique de navigation.

    constructor() {
        this.m_hash_pages = {};
        this.m_hash_functions = {};
        //Écoute l'événement de changement d'URL (hashchange) pour exécuter la fonction onHashChanged.
        $(window).on('hashchange', (event) => {
            this.onHashChanged(event);
        });
        MSApp.instance = this;
        //Associe des gestionnaires d'événements aux liens (<a>) pour intercepter les clics sur les liens avec des href commençant par '##' et exécuter les fonctions associées.
        $('body').on('click', 'a', (e) => {
            const href = $(e.currentTarget).attr('href');
            if (href && href.substring(0, 2) == '##') {
                e.preventDefault();
                //console.log(`function ${href}`);
                const query = MSApp.hashToQuery(href.substring(1));
                if (this.m_hash_functions[query.hash]) {
                    this.m_hash_functions[query.hash](query);
                } else {
                    console.warn(`hash function ${query.hash} not found`);
                }
                return true;
            }
        });
    }
    // Enregistre une page avec sa référence dans m_hash_pages.
    registerPage(iPage, iRef = iPage.m_ref) {
        iPage.m_type = 'page';
        this.m_hash_pages[iRef] = iPage;
        //this.showMenu(iPage.m_ref)
    }
    //Enregistre un panneau avec sa référence dans m_hash_pages.
    registerPane(iPane, iRef, iPage) {
        iPane.m_type = 'pane';
        this.m_hash_pages[iRef] = iPage;
        iPage.registerPane(iPane, iRef);
        //this.showMenu(iPage.m_ref)
    }

    getPage(iRef) {
        return this.m_hash_pages[iRef];
    }

    // Enregistre une fonction associée à un hash dans m_hash_functions.
    registerHashFunction(iRef, iFunc) {
        this.m_hash_functions[iRef] = iFunc;
        //this.showMenu(iRef)
    }

    /*
    Page par défaut quand on change l'url de la page
    */
    defaultHash() {
        return { hash: this.m_current_page_ref ?? 'home' };
    }


    /*
    Méthode appelée lorsqu'il y a un changement de hash dans l'URL.
    */
    onHashChanged(event) {
        //console.log(`onHashChanged ${window.location.hash}`);
        this.onQueryChanged(MSApp.hashToQuery(window.location.hash));
    }

    // Vrai i la page est enregistrée
    pageExists(iHash) {
        return this.m_hash_pages[iHash] ? true : false;
    }

    //Méthode appelée lorsqu'il y a un changement dans la query string de l'URL
    onQueryChanged(iQuery) {
        if (iQuery.hash[0] == '#') {
            iQuery.hash = iQuery.hash.substring(1);
            if (this.m_hash_functions[iQuery.hash]) {
                this.m_hash_functions[iQuery.hash](iQuery);
            } else {
                console.warn(`hash function ${iQuery.hash} not found`);
            }
            return;
        }

        let ref = iQuery.hash;
        let page = this.m_hash_pages[ref];
        if (!page) {
            if (ref != '') {
                console.warn(`Page '${ref}' not found, switching to default`);
            }
            ref = this.defaultHash();
            page = this.m_hash_pages[ref.hash ?? ref];
            if (!page) {
                console.error("No default hash");
            } else {
                this.setHash(this.defaultHash());
            }
            return;
        }

        if (typeof (page) == 'function') {
            page = page();
            this.m_hash_pages[iQuery.hash] = page;
        }

        if (typeof (page) == 'object' && page instanceof Promise) {
            delete this.m_hash_pages[iQuery.hash];
            page.then((res) => {
                this.m_hash_pages[iQuery.hash] = res;
            }).catch(() => {
                console.error(`Failed to create Page ${iQuery.hash}`, err);
            }).finally(() => {
                this.onQueryChanged(iQuery);
            });
            return;
        }

        if (!page.m_initialized) {
            const p = page.init(iQuery);
            if (p && p instanceof Promise) {
                page.m_initialized = 'pending';
                p.then(() => {
                    console.log(`Page ${ref} initialized (async)`);
                }).catch((err) => {
                    console.error(`Failed to initialize page ${ref}`, err);
                }).finally(() => {
                    page.m_initialized = true;
                    this.onQueryChanged(iQuery);
                });
                return;
            }
            console.log(`Page ${ref} initialized (sync)`);
            page.m_initialized = true;
        }

        if (page.m_initialized == 'pending') {
            return;
        }

        if (this.m_current_page != page) {
            this.openPage(page, iQuery);
        } else {
            this.m_current_page.onQueryChanged(iQuery);
        }
    }
    //Modifie les paramètres d'un hash dans l'URL.
    patchHash(iHashParams) {
        const hash = window.location.hash.substring(1);  // remove #
        const pos = hash.indexOf('?');
        let params;
        if (pos >= 0) {
            params = Object.fromEntries(new URLSearchParams(hash.substring(pos)));
            params.hash = hash.substring(0, pos);
        } else {
            params = { hash: hash };
        }
        let changed = false;
        for (const key in iHashParams) {
            if (iHashParams[key] != params[key]) {
                changed = true;
                params[key] = iHashParams[key];
            }
        }
        if (changed) {
            this.setHash(params);
        }
    }

    static hashToQuery(iHash) {
        let query;
        const hash = iHash.substring(1);  // remove #
        const pos = hash.indexOf('?');
        if (pos >= 0) {
            query = Object.fromEntries(new URLSearchParams(hash.substring(pos)));
            query.hash = hash.substring(0, pos);
        } else {
            query = {
                hash: hash
            };
        }
        return query;
    }

    static buildHash(iHash) {
        if (typeof (iHash) == 'string') {
            return iHash;
        }
        let res = '#' + iHash.hash;
        let separator = '?';
        for (const key in iHash) {
            if (key != 'hash') {
                res += separator + encodeURIComponent(key) + '=' + encodeURIComponent(iHash[key]);
                separator = '&';
            }
        }
        return res;
    }
    //Définit le hash dans l'URL et rafraîchit la page si nécessaire.
    setHash(iHash, iRefreshPage = false) {
        window.location.hash = MSApp.buildHash(iHash);
        if (iRefreshPage) {
            location.reload();
            // setTimeout(() => {location.reload();}, 1);
        } else {
            this.onQueryChanged(MSApp.hashToQuery(window.location.hash));
        }
    }

    getHash() {
        return MSApp.hashToQuery(window.location.hash);
    }

    //ouvre une page spécifique en fonction du hash fourni.
    openPage(iPage, iQuery) {
        if (iPage.m_initialized !== true) {
            console.error("The page should be initialized", iPage);
            return;
        }

        if (!iPage.$m_div) {
            iPage.$m_div = iPage.$create(iQuery);
            iPage.$m_div.addClass('page').hide();
            iPage.$m_div.appendTo($('#page_container'));
            iPage.onAfterCreate(iQuery);
        }

        iPage.onBeforeOpen(iQuery);
        if (this.m_current_page) {
            this.m_current_page.onClose();
            this.m_current_page.$m_div.hide();
        }
        this.m_current_page = iPage;
        this.m_current_page_ref = iPage.m_ref;
        iPage.onOpen(iQuery);
        iPage.$m_div.show();
        iPage.onAfterOpen(iQuery);
        iPage.applySeo();
    }

    // Ouvre une page en fonction du hash de la query string de l'URL.
    openHashPage(iQuery) {
        const page = this.m_hash_pages[iQuery.hash];
        if (!page) {
            console.warn(`Page '${iQuery.hash}' not found`);
            return;
        }
        if (this.m_current_page_ref == page.m_ref) {
            page.query(iQuery);
            return;
        }

        this.openPage(page, iQuery);
    }

    defaultPageTitle() {
        return 'Mon application';
    }

    seoActive() {
        // console.log("MSApp - seoActive")
        return false;
    }
}

if (!String.prototype.format) {
    String.prototype.format = function () {
        var args = arguments;
        return this.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] != 'undefined'
                ? args[number]
                : match
                ;
        });
    };
}

